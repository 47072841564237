/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        //fixing responsive menu appearance
        var listItems = $(".resp-sub-nav li:not(.menu-item-has-children)");
        listItems.each(function(idx, li) {
            $(this).removeClass('uk-parent');
        });



        //function that detects if an element is in viewport
        $.fn.isInViewport = function() {
          var elementTop = $(this).offset().top;
          var elementBottom = elementTop + $(this).outerHeight();

          var viewportTop = $(window).scrollTop();
          var viewportBottom = viewportTop + $(window).height();

          return elementBottom > viewportTop && elementTop < viewportBottom;
        };


        //When stats are in viewport on home page -> start couting
        $(window).on('resize scroll', function() {
            if( $('.home-stats-wrap').length ){
                if ($('.home-stats-wrap').isInViewport()) {
                    //couter function for home page
                    $('.counter-stat').each(function() {
                      var $this = $(this),
                          countTo = $this.attr('data-count');

                      $({ countNum: $this.text()}).animate({
                        countNum: countTo
                      },

                      {
                        duration: 2000,
                        easing:'linear',
                        step: function() {
                          $this.text(Math.floor(this.countNum));
                        },
                        complete: function() {
                          $this.text(this.countNum);
                          //alert('finished');
                        }

                      });
                    });
                }
            }

        });



        //For Case study page if there is "Results" image add margin of the block for not breaking the styles
        $(document).ready(function() {
          $(window).load(function() {
             //This will run only after page is fully loaded in background.
             if ( $( ".with-image" ).length ) {
                 var margin = $(".results-img").height()-150;
                 console.log('margin');
                 console.log(margin);
                 $( ".res-img-overlay" ).css( "margin-bottom", margin );
             }
          });
        });



        //Catch click to Blog Article Category and go to Blog page and show that spevific one
        $('.post .category').click(function(){

            window.location = "/blog";
            alert( "Handler for .click() called." );
            console.log($('#li-test'));
            $('.blog-page .nav-wrap li').each(function(){
                console.log($(this));
                var term = $('a').val();
                alert(term);
            });
        });



        var howManyCases = 5;

        if ($(window).width() > 1599) {
           howManyCases = 5;
        }
        else if($(window).width() > 1199) {
           howManyCases = 4;
        }
        else if($(window).width() > 959) {
           howManyCases = 3;
        }
        else if($(window).width() > 639) {
           howManyCases = 2;
        }
        else {
           howManyCases = 1;
        }

        $('.slider-for').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.slider-nav'
        });
        $('.slider-nav').slick({
          slidesToShow: howManyCases,
          slidesToScroll: 1,
          asNavFor: '.slider-for',
          focusOnSelect: true,
          prevArrow: '<span class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
          nextArrow: '<span class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
        });





        /**

            This fiddle is using the latest version
            of Slick (from master) and jQuery.

            If your issue occurs in older version or a
            specific tag; please use the "External Resources"
            section in the sidebar to correct it.

        **/

        function createSlick(){

        	$(".slider").not('.slick-initialized').slick({
            autoplay: true,
            dots: true,
            responsive: [{
                breakpoint: 500,
                settings: {
                    dots: false,
                    arrows: false,
                    infinite: false,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }]
        });

        }

        createSlick();

        //Now it will not throw error, even if called multiple times.
        $(window).on( 'resize', createSlick );





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        //initialising Integrations Logos carousel
        var carousel = $("#integrations-carousel").flipster({
            loop: true,
            style: 'carousel',
            spacing: -0.5,
            touch: true,
            nav: 'after',
            buttons:   true,
        });



/*
        var nextPrev = false;

        $(document).on("click",".uk-slidenav-previous", function (event) {
            nextPrev = false;
        });
        $(document).on("click",".uk-slidenav-next", function (event) {
            nextPrev = true;
        });

        //Catching hide event to show/hide Case Studies info in slider
        $(function () {
            $(document).on('itemhidden', 'div.uk-slider', function(event) {
                //console.log(event);
                //console.log('Item hidden');
                //console.log(event.target);

                //hide description and logo of case study
                $( event.target ).children('.case-desc-wrap').first().addClass('uk-hidden');
                $( event.target ).children('.inverted-logo-wrap').first().children().first().addClass('uk-hidden');
                $( event.target ).children('.normal-logo').first().removeClass('uk-hidden');
            });
        });


        $('.uk-slidenav-next').on('click', function(e) {
            nextPrev = true;
            $(function () {
                $(document).on('itemhide', 'div.uk-slider', function(event) {
                    //console.log(event);
                    //console.log(event.target);
                    var $ancestor = $('.uk-slider-items');
                    var $next= $( event.target ).next();

                    // If there wasn't a next one, go back to the first.
                    if( $next.length == 0 ) {
                        $next = $ancestor.children().first();
                    }
                    //console.log($next);

                    //show description and inverted logo for new case study
                    $next.children('.case-desc-wrap').first().removeClass('uk-hidden');
                    $next.children('.normal-logo').first().addClass('uk-hidden');
                    $next.children('.inverted-logo-wrap').first().children().first().removeClass('uk-hidden');
                    $next.next().children('.normal-logo').first().css( "margin-left","50px" );

                });


            });
        });

        $('.uk-slidenav-previous').on('click', function(e) {
            nextPrev = false;
            $(function () {
                $(document).on('itemhide', 'div.uk-slider', function(event) {
                    //console.log(event);
                    //console.log(event.target);
                    var $ancestor = $('.uk-slider-items');
                    var $next = $( event.target ).prev();

                    // If there wasn't a next one, go back to the first.
                    if( $next.length == 0 ) {
                       $next = $('.uk-slider-items li:last-child');
                    }
                    //console.log($next);

                    //show description and inverted logo for new case study
                    $next.children('.case-desc-wrap').first().removeClass('uk-hidden');
                    $next.children('.normal-logo').first().addClass('uk-hidden');
                    $next.children('.inverted-logo-wrap').first().children().first().removeClass('uk-hidden');
                    $next.next().children('.normal-logo').first().css( "margin-left","50px" );

                });
            });

            $(function () {
                $(document).on('beforeitemshow', 'div.uk-slider', function(event) {
                    //console.log(event);
                    //console.log('Item shown');
                    //console.log(event.target);
                    var $ancestor = $('.uk-slider-items');
                    var $next = $( event.target ).next().next().next().next();
                    // If there wasn't a next one, go back to the first.
                    if( $next.length == 0 ) {
                        var $nextTwo = $( event.target ).next().next().next();
                        var $nextThree = $( event.target ).next().next();
                        var $nextFour = $( event.target ).next();

                        if($nextTwo.length == 0 ){
                            $next = $ancestor.children().first().next();
                        }
                        if($nextThree.length == 0){
                            $next = $ancestor.children().first().next().next();
                        }
                        if($nextFour.length == 0){
                            $next = $ancestor.children().first().next().next().next();
                        }
                        if($nextTwo.length == 1 && $nextThree.length == 1 && $nextFour.length == 1){
                            $next = $ancestor.children().first();
                        }

                    }
                    $next.children('.normal-logo').first().css("margin-right","5vw" );
                });
            });

        });

*/




      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.





/**
 * Social popup handler
 */
// create social networking pop-ups
/*
(function() {
    // link selector and pop-up window size
    var Config = {
        Link: "a.share",
        Width: 500,
        Height: 500
    };
    window.__twitterIntentHandler = true;

    // create popup
    function PopupHandler(e) {

        e = (e ? e : window.event);
        var t = (e.target ? e.target : e.srcElement);
        //track share event if trac function available
        if (typeof window.rmTracEvent === 'function') {

            var rmevent = 'Share Click';
            var rmcategory = window.url( 'domain', t.href );
            var rmlabel = '';

            if ( rmcategory === 'facebook.com') {
                rmlabel = decodeURIComponent( window.url( '?u', t.href ) );
            }else{
                rmlabel = decodeURIComponent( window.url( '?url', t.href ) );
            }

            //console.log( rmevent, rmcategory, rmlabel );
            window.rmTracEvent( rmevent, rmcategory, rmlabel );
        }



        // popup position
        var
            px = Math.floor(((screen.availWidth || 1024) - Config.Width) / 2),
            py = Math.floor(((screen.availHeight || 700) - Config.Height) / 2);

        // open popup
        var popup = window.open(t.href, "social",
            "width="+Config.Width+",height="+Config.Height+
            ",left="+px+",top="+py+
            ",location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1");
        if (popup) {
            popup.focus();
            if (e.preventDefault){ e.preventDefault();
            e.returnValue = false;}
        }

        return !!popup;
    }


    // add handler links
    var slink = document.querySelectorAll(Config.Link);
    for (var a = 0; a < slink.length; a++) {
        slink[a].onclick = PopupHandler;
    }



}());
*/
